<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <router-link :to="{ name: 'managers_all' }">
                <el-button type="primary">
                    {{ $t('managers.go_back') }}
                </el-button>
            </router-link>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
        </div>

        <ManagersArchiveTable :data="managersDataOrigin" @handleRestore="handleRestore" @handleDelete="handleDelete" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    components: {
        ManagersArchiveTable: () => import(/* webpackChunkName: "Managers/ManagersArchiveTable" */ './components/ManagersArchiveTable'),
    },

    data() {
        return {
            noDataInformation:  false,
            managersData:       [],
            managersDataOrigin: [],
            quickSearch:        '',
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_managers_archive');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('managers.managers_archive'));
        this.$store.commit('setActiveMenuItem', '4-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.quickSearch = '';
            this.$wait.start('loading');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers/archived`);
                this.managersData = Object.freeze(data);
                this.managersDataOrigin = Object.freeze(data);
                this.noDataInformation = data.length;
                this.$wait.end('loading');
                this.$wait.end('loading.initial_managers_archive');
            } catch (e) {
                this.$wait.end('loading');
                this.$wait.end('loading.initial_managers');
            }
        },

        async handleRestore(uuid) {
            this.$wait.start('loading');
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/managers/${uuid}/restore`);
                this.$notify.success({ title: 'Success' });
                this.$wait.end('loading');
                this.getAll();
            } catch (error) {
                this.$wait.end('loading');
            }
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/managers/${uuid}/destroy`,
                actionType: 'destroy',
            });
        },

        filter() {
            this.managersData = this.managersDataOrigin.filter(manager => (
                manager.name.toLowerCase().includes(this.quickSearch.toLowerCase())
                || (manager.surname && manager.surname.toLowerCase().includes(this.quickSearch.toLowerCase()))
            ));
        },
    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
    .el-button--mini {
        padding: 5px 5px;
    }
</style>
